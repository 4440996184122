@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&family=Preahvihear&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");

body {
  margin: 0;
  line-height: 1.5;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*{
  margin:0;
  padding:0;
  box-sizing: border-box;
}

::before ::after{
  box-sizing: border-box;
}

img{
  max-width: 100%;
  vertical-align: middle;
}

.navbar{
  display: flex;
  position: sticky;
  top: 0;
  z-index: 10;
  flex-wrap: wrap;
  height:50px;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(80, 80, 80);

  .brand {
    padding: 0px 25px;
    font-size:x-large;
    font-family: 'Dancing Script', cursive;
    color: aliceblue;
  }

  .linkcontainer {
    margin-right: 50px;

    .link {
      text-decoration: none;
      font-size: 12px;
      margin-left: 20px;
      color: aliceblue
    }
  }
}

.commandbar{
  padding: 10px;
  display: flex;
  justify-content: space-between;

  .buttonContainer {
    display: flex;
    justify-content: start;
  }

  .searchbar{
    max-width: 400px;
  }
}

.rsvpform{
  width: 90vw;
  padding: 20px;
  margin: 20px;
  background-color: white;
  color: black;

  label {
    width: 100%;
    text-align: left;
    display: block;
  }

  h3{
    margin-bottom: 20px;
  }

  button {
    background: #f1356d;
    color: #fff;
    border: 0;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
  }
}

.thankyounote{
  background-color: white;
  max-height: 90%;
  margin: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    flex-grow: 1;
    border-radius: 10px;
    max-width: 400px;
    max-height: 90%;
    width: 90%;
  }
}

.achtergrond{
  background-image: url('./content/img/frankrijk.jpg');
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.fullscreen{
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.cover{
  height: auto;
  align-items: start;
  margin: auto;
  width: 100vw;
  color: rgb(109, 109, 109);
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-family: 'Preahvihear', sans-serif;
  background-color: white;
}

.photo{
  background-color: transparent;
  padding: 20px;
}

.disclaimer {
  font-family: 'Preahvihear', 'sans-serif';
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.658);
  color: white;
  display: flex;
  gap: 15px;
  flex-direction: column;
  align-items: center;

  .description {
    text-align: center;
  }
}

.dark{
  background-color: rgba(0, 0, 0, 0.658);
  color: white;
}

.infoSection {
  padding: 15px;
  display: flex;
  flex-flow: row wrap;
  gap: 10px;
  justify-content: space-between;

  .event{
    width: 250px;
    display: flex;
    padding: 15px;
    flex-direction: column;
    color: black;
    flex-grow: 1;
    gap: 20px;
    text-decoration: none;
    text-align: center;
    justify-content: space-between;
    // align-content: space-between;

    .eventPictureContainer {
      height: 220px;
      align-items: center;

      .eventPicture {
        height: 200px;
        width: 200px;
        margin: auto;
        object-fit: cover;
        border-radius: 100px;
      }
    }

    .textSection {
      display: flex;
      flex-direction: column;
      gap: 15px;
      .title {
        font-weight: bold;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .infoVeld {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .label {
          font-weight: bold;
        }
      }

    }
  }
}

.titlebanner{
  display: flex;
  width:100%;
  height: 50vh;
  min-height: calc(200px + 5vw);
  background-color: rgba(0, 0, 0, 0.4); 
  padding: 0px 25px;
  margin: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color:white;
  font-size:auto;
  font-size: 30px;
  
  .namen{
    font-family: 'Dancing Script', cursive;
    font-size: calc(20px + 3vw);
  }
  
  .statement{
    text-transform: uppercase;
    font-size: calc(12px + 1vw);
  }
  
  .datum{
    text-transform: uppercase;
    font-size: calc(12px + 1vw);
    margin-top: 20px;
    border: 1px solid white;
    border-radius: 30px;
    padding: 7px 30px;
  }
}

.leftalign{
  align-items:start;
  margin:50px;
  width: 100%;
  max-width: 500px;
}

.scrolldown{
  margin-left: auto;
  margin-right: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  font-size: 20px;
  color: white;
  height: 75px;
  width: 200px;
  border-radius: 50px;
  background-color: rgba(0, 0, 0, 0.4); 
  
}

.verhaal{
  flex-grow: 2;
  font-size: calc(10px + 0.3vw);

  h3{
    margin-bottom: 20px;
    font-size:xx-large;
  }

  .foto {
    max-width: 300px;
    border-radius: 10px;
    margin: auto;
    filter: grayscale(100%);
  }
}

.withnav{
  height: calc(100vh - 50px);
}

.infopage{
  font-family: 'Preahvihear', 'sans-serif';
  color: black;
  padding: 20px;
  max-width: 100vw;
  background-color: rgba(250, 235, 215, 0.75);
  
  .headerSection {
    text-align: center;
    font-size: 30px;
  }

  .introSection {
    padding: 15px;
  }

  .infoSection {
  
    .event{
      width: 250px;
      display: flex;
      padding: 15px;
      flex-direction: column;
      color: black;
      flex-grow: 1;
      gap: 20px;
      text-decoration: none;
      text-align: center;
      justify-content: space-between;
      // align-content: space-between;
  
      .eventPictureContainer {
        height: 220px;
        align-items: center;
  
        .eventPicture {
          height: 200px;
          width: 200px;
          margin: auto;
          object-fit: cover;
          border-radius: 100px;
        }
      }
  
      .textSection {
        display: flex;
        flex-direction: column;
        gap: 15px;
        .title {
          font-weight: bold;
        }
      }
  
      .info {
        display: flex;
        flex-direction: column;
        gap: 15px;
  
        .infoVeld {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .label {
            font-weight: bold;
          }
        }
  
      }
    }

    .info {
      padding: 15px;
      width: 100%;
      display: flex;
      flex-flow: column;
      gap: 10px;
      justify-content: space-between;

      .title {
        text-align: center;
        font-size: 30px;
      }

      .infoPicture {
        max-height: 200px;
        min-width: 200px;
        max-width: 60vw;
        margin: auto;
        object-fit: cover;
        border-radius: 100px;
      }

      hr {
        box-shadow: 0px 0px 10px black;
      }
    }
  }
}



